<template>
  <v-main id="pages">
    <v-img gradient="to top, rgba(238, 238, 238, 1), rgba(238, 238, 238, 1)" min-height="100%">
      <router-view />
    </v-img>
  </v-main>
</template>

<script>
export default {
  name: 'AppView',
  computed: {
    src() {
      return this.srcs[this.$route.path];
    },
  },
};
</script>
