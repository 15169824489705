<template>
  <div>
    <app-view />
    <v-row justify="center">
      <error-dialog />
      <success-snackbar />
    </v-row>
  </div>
</template>

<script>
import AppView from '@/AppView';

export default {
  name: 'AppIndex',
  components: {AppView},
};
</script>
